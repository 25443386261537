import { connect } from 'react-redux'
import { Radio, Submit } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const TinyHome = (props) => {
    let condition =
        get(props, 'data.address.formatted', null) &&
        get(props, 'data.isStrata', null) &&
        get(props, 'data.hasPlatform', null) === 'true' &&
        get(props, 'data.propertyType', null) &&
        get(props, 'data.propertyType', null) !== 'other' &&
        get(props, 'data.propertyType', null) !== 'caravan' &&
        get(props, 'data.propertyType', null) !== 'temporary' &&
        get(props, 'data.propertyType', null) !== 'retirement' &&
        (get(props, 'data.policy', null) ||
            (get(props, 'data.wellMaintained', null) &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID)) &&
        get(props, 'data.endDate', null) &&
        get(props, 'data.numberOfGuests', null) &&
        get(props, 'data.atProperty', null) &&
        get(props, 'data.excess', null)

    return condition ? (
        <>
            <Radio
                name="purchase"
                id="purchase"
                label="Would you like to purchase this policy?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />

            {props.data.purchase === 'true' &&
                props.quote &&
                !props.loading && <Submit id="submit" label="Submit" />}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    quote: get(state, 'quote.quote', null),
    product: get(state, 'product.product', {}),
    loading: get(state, 'quote.loading', false)
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(TinyHome)
